// Colors
$main-color: #FF7070;
$alt-color: #FAA987;
$text-color: #908E99;
$meta-color: #908E99;
$heading-color: #49484F;
$bg-light: #F3F2F5;
$bg-dark: #232329;
$border-color: #E2E1E8;
$border-dark-color: #26282d;


// Fonts
$body-font: 'Roboto', sans-serif;
$heading-font: 'Rubik', sans-serif;


// Navbar
$nav-height: 55px;